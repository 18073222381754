import React from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import RegionyTemplateV2 from "../templates/RegionyTemplateV2";



const faqData = [
  {
    otazka: "Co všechno obsahuje cena?",
    odpoved: `Ta obsahuje vyzvednutí dítěte kdekoliv po daném regionu a blízkém okolí, převezení do krematoria, samotný žeh a následné uložení do dočasné urny. V ceně je také administrativa spojená s vyřízením <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a> a možnost obracet se na naši zákaznickou linku 24 hodin 7 dní v týdnu.`
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí miminka došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.`  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo porodnici, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];



const DetskyPohreb = ({ data }) => {

  return (
    <StaticQuery
      query={graphql`
        query {
          jumboObrazek: file(relativePath: { eq: "mimina.png" }) {
            childImageSharp {
              fixed(width: 460) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          jumboObrazekMobile: file(relativePath: { eq: "mimina_m.png" }) {
            childImageSharp {
              fixed(width: 460) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => ( <>
        <RegionyTemplateV2
          baby
          name="Pohřeb pro miminko"
          slug="pohreb-miminka"
          faqData={faqData}

          seoTitle="Důstojný pohřeb dítěte, miminka | Pohřební služba Goodbye"
          seoDesc="Pomůžeme vám sjednat pohřeb pro vaše dítě, miminko (perinatální ztráta). Budeme vám oporou 24/7: Nejlépe hodnocená pohřební služba v ČR za 2022"
          ratingCount={62}

          h1="Pohřební služba pro děti"
          perex={<>
            Pohřeb dítěte je jednou z nejnáročnějších situací, která vás může jako rodiče potkat. Postaráme se o důstojné rozloučení s vaším miminkem tak, jak si budete přát. Společně naplánujeme nejen pohřeb, ale pomůžeme vám také najít nejlepší cestu, jak si na vaše dítě uchovat památku. V případě potřeby vás propojíme s organizacemi, které vám budou schopné v nadcházejících týdnech poskytnout emoční podporu.
            <br/><br/>
            Zařídíme vše potřebné abyste se vy a vaše rodina mohli věnovat v těchto těžkých časech především jeden druhému.
          </>}
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          reviewDesc="Nejlépe hodnocená pohřební služba v ČR za rok 2022"

          sklonovaneMesto=""

          introH2="V čem jsme jiní než ostatní pohřební služby při perinatální ztrátě?"
          introText={<><Link to="/poradna/umrti-miminka/">Úmrtí miminka</Link> je velmi těžké a náročné období v životě, které přichází nečekaně a nikdo na něj není připravený. Sami jsme se v minulosti setkali s neempatickým přístupem několika pohřebních služeb. Vypravení pohřbu bylo chladné, neosobní a vždy za neúměrně vysokou cenu. To jsme se rozhodli změnit.<br/><br/>Založili jsme Goodbye s důrazem na osobní přístup a transparentnost – jako pohřební službu, kterou bychom s klidným srdcem nabídli vlastní rodině a přátelům. U nás nemusíte nikam chodit. Všechny náležitosti vyřídíte přes internet s předem stanovenou cenou. Náš empatický tým vám pomůže vybrat to nejlepší řešení pro vás, ať už se jedná o kremaci bez obřadu, výběru památečních předmětů nebo odkázání na organizace, které poskytují podporu rodinám, které potkala ztráta dítěte.</>}

          procesDesc="V klidu vás provedeme celým procesem a zařídíme, co je potřeba. Věříme, že si každý zaslouží důstojné poslední rozloučení. Uděláme vše pro to, abychom našli tu správnou cestu i pro vás."
          procesText1={<>Zajistíme převoz vašeho dítěte z nemocnice nebo porodnice do našeho zázemí. Fungujeme ve 24 městech po celé ČR.</>}
          procesText2={<>Pomůžeme vám najít způsob, jak si na vaše miminko nechat malou památku: například památeční předmět nebo miniurna.</>}
          procesText3={<>Zajistíme pietní a důstojnou <Link to="/poradna/kremace-zajimavosti/">kremaci</Link> v našem partnerském krematoriu. Mezi tím za vás vyřešíme veškerou dokumentaci a případně i <Link to="/slovnik-pojmu/umrtni-list/">úmrtní list</Link> na matrice.</>}
          procesText4={<>Urnu si u nás můžete vyzvednout nebo vám ji přivezeme až domů. Popel potom můžete <Link to="/slovnik-pojmu/rozptyl-vsyp-popela/">rozptýlit</Link>, <Link to="/slovnik-pojmu/hrob/">uložit do kolumbária</Link> či například <Link to="/poradna/kremacni-popel/">zasadit strom</Link>. Je to jen na vás.</>}

        

          mestoH2="Uchování památky na vaše miminko"
          mestoText={<>
            Vyrovnání se s potratem nebo <Link to="/poradna/umrti-miminka/">úmrtím dítěte</Link> v perinatálním období je náročný proces. Uschovaná památka na miminko vás bude provázet a pomáhat vám na cestě truchlení. Existuje mnoho možností, jak si nechat malý památeční předmět nebo jakým způsobem uložit popel.<br/><br/>
            Jednou z variant je odsysp malého množství popela po kremaci, který je následně přimíchám do skla. Z něj se následně vyrábí předměty, jako přívěsky na řetízek či náramek. Je možné nechat si vyrobit i větší předměty, jako jsou například těžítka, sošky nebo olejové lampičky.<br/><br/>
            Další variantou, jak uchovat popel vašeho novorozence, je uložení do miniurny, kterou potom můžete mít doma stále nablízku. Tyto speciálně vyráběné urny menší velikosti je možné pořídit v mnoha variantách barev, materiálů atd. S výběrem vhodné urničky právě pro vás vám v naší pohřební službě rádi pomůžeme.<br/><br/>
            Při výběru ekologické urny pro uložení popela je možné uspořádat poslední rozloučení pro nejbližší rodinu, při kterém společně zasadíte strom a v ekologické urně uložíte popel vašeho dítěte ke kořenům zasazeného stromu. Vzrostlý strom se pro vás později může stát místem, kam budete chodit na vaše miminko vzpomínat.<br/><br/>

          </>}

          featuresH2="Nejste na to sami. Se vším vám pomůžeme."
          featuresText1={<>S námi celý pohřeb naplánujete z bezpečí domova. Nemusíte nikam chodit. Vše si můžete v klidu rozmyslet a poradit se s rodinou.</>}
          featuresText2="Cena, na které se s naší pohřební službou domluvíte, je vždy konečná. Obsahuje kompletně vše, co budete potřebovat."
          featuresText3="Chápeme, jak náročným obdobím procházíte. Ulehčíme vám od všech administrativních úkonů a propojíme s těmi správnými organizacemi."
          
          seVsimPomuzemeText="Úmrtí miminka nebo perinatální ztráta je velmi náročnou situací pro celou rodinu. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše dítě to nejosobnější poslední rozloučení."
          
        />
      </>)}
    />
  );
}

export default DetskyPohreb;


